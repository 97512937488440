* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: auto;
}

html,
body {
  font-family: 'Play', sans-serif;
  font-size: 15px;
  color: #505050;
  line-height: 26px;
  font-weight: 400;
  max-width: 100%;
  overflow-x: hidden;
}
